body {
    overflow-x: hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    * {
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;         /* Opera/IE 8+ */
    }
}

@font-face {
    font-family: "OpenSans";
    src: url("assets/fonts/OpenSans.ttf");
}

* {
    font-family: OpenSans, sans-serif;
    margin: 0;
}

button {
    transition: 0.2s;
    cursor: pointer;
}

button:hover {
    opacity: 0.8;
}

html {
    font-size: 62.5%;
}

@media (min-width: 576px) {
    html {
        font-size: 65%; /* 1rem = 10.4px */
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    html {
        font-size: 67.5%; /* 1rem = 10.8px */
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    html {
        font-size: 70%; /* 1rem = 11.2px */
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    html {
        font-size: 75%; /* 1rem = 12px */
    }
}

/* Extra extra large devices (larger desktops, 1600px and up) */
@media (min-width: 1600px) {
    html {
        font-size: 80%; /* 1rem = 12.8px */
    }
}


body {
    font-size: 1.6rem; /* 16px */
}

h1 {
    font-size: 3rem; /* 32px */
}

h2 {
    font-size: 2.4rem; /* 24px */
}

p {
    font-size: 1.6rem; /* 16px */
    margin-bottom: 0;
}

/* Additional styles for other elements */
h3 {
    font-size: 2rem; /* 20px */
}

h4 {
    font-size: 1.8rem; /* 18px */
}

small {
    font-size: 1.2rem; /* 12px */
}

button {
    font-size: 1.6rem;
}